<template>
  <div>
    <div class="form-header font-weight-bolder px-15 py-5">{{ $t('label.password_recovery') }}</div>
    <div v-if="tokenState === 2 && !passwordChanged" class="px-15 py-10 py-7">
      <div class="font-weight-bolder">{{ $t('placeholder.enter_new_password') }}</div>
      <div class="mt-2">{{ $t('description.new_password_rules') }}</div>
      <floating-input-form-group
        class="my-5"
        :value="form.password"
        :placeholder="$t('label.new_password')"
        input-type="password"
        :error="error.password"
        @input="form.password = $event"
      />

      <floating-input-form-group
        class="mb-15 mb-sm-5"
        :value="form.repeated_password"
        :placeholder="$t('placeholder.enter_new_password')"
        input-type="password"
        :error="error.repeated_password"
        @input="form.repeated_password = $event"
      />

      <div class="text-center">
        <button class="btn btn-green font-weight-bold px-9 py-4 my-3 font-size-3 w-100 mb-5" @click="submitForm">
          {{ $t('label.password_recovery') }}
        </button>
      </div>
    </div>
    <div v-if="tokenState === 1" class="px-15 py-10">
      <div class="text-left">
        <div class="font-size-lg text-danger mt-2">{{ $t('error.reset_token_expired') }}</div>
        <button class="btn btn-green font-weight-bold px-9 py-4 my-3 font-size-3 w-100 mt-15"
                @click="goToForgotPassword">
          {{ $t('btn.reset_password') }}
        </button>
      </div>
    </div>
    <div v-if="passwordChanged" class="px-15 py-10">
      <div class="text-center">
        <inline-svg src="/media/svg/icons/General/Success.svg"/>
        <div class="h4 mt-2">{{ $t('description.password_changed') }}</div>
        <button class="btn btn-green font-weight-bold px-9 py-4 my-3 font-size-3 w-100 mt-15" @click="goBackToLogin">
          {{ $t('label.authorization') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {CHECK_RESET_TOKEN, CHANGE_PASSWORD} from '@/store/core/auth.module';
import FloatingInputFormGroup from '@/components/elements/form-groups/FloatingInputFormGroup.vue';

const TOKEN_NOT_CHECKED = 0;
const TOKEN_INVALID = 1;
const TOKEN_VALID = 2;

export default {
  name: 'resetPassword',
  components: {
    FloatingInputFormGroup
  },
  data: () => ({
    token: '',
    tokenState: TOKEN_NOT_CHECKED,
    passwordChanged: false,
    form: {
      password: '',
      repeated_password: ''
    },
    error: {
      password: '',
      repeated_password: ''
    }
  }),
  async beforeMount() {
    this.token = this.$route.query?.token;
    if (!this.token) this.$router.push({name: 'login'});

    await this.$store.dispatch(CHECK_RESET_TOKEN, this.token)
      .then(() => this.tokenState = TOKEN_VALID)
      .catch(() => this.tokenState = TOKEN_INVALID);
  },
  computed: {
    isPasswordValid() {
      const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*\s).{8,}$/;
      return regex.test(this.form.password);
    }
  },
  methods: {
    validateForm() {
      this.error.password = !this.form.password
        ? 'placeholder.enter_new_password'
        : !this.isPasswordValid
          ? 'error.password_security_failed'
          : '';

      this.error.repeated_password = !this.form.repeated_password
        ? 'placeholder.repeat_new_password'
        : this.form.repeated_password !== this.form.password
          ? 'error.passwords_not_the_same'
          : '';

      return !Object.values(this.error).some(v => (v !== null && v !== ''));
    },
    submitForm() {
      if (!this.validateForm()) return false;

      this.$store.dispatch(CHANGE_PASSWORD, {token: this.token, payload: this.form})
        .then(() => this.passwordChanged = true)
        .catch(() => this.tokenState = TOKEN_INVALID);
    },
    goToForgotPassword() {
      this.$router.push({name: 'forgotPassword'});
    },
    goBackToLogin() {
      this.$router.push({name: 'login'});
    },
    clearForm() {
      for (let item in this.form) this.form[item] = '';
    },
    clearErrors() {
      for (let item in this.error) this.form[item] = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.form-header {
  border-bottom: 1px solid #6E7173;
}
</style>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .form-header {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
    font-size: 16px;
  }
}
</style>